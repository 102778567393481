<template>
	<MhRouterView class="DevView view" :isContentReady="isContentReady">
		<div class="view__inner">
			<div class="minHeight100vhMinusMainHeader">

				<br /><strong>DevView.vue</strong><hr /><br />

				<br /><br />

				<Img2></Img2>

				<br /><hr />

				<div style="font-family: ABCGrow-BD">
					ABCGrow-BD • Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
				</div>
				<hr/>
				<div style="font-family: ABCGrow-D">
					ABCGrow-D • Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
				</div>
				<hr/>
				<div style="font-family: system85-medium">
					System85-medium • Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
				</div>

			</div>
		</div>
	</MhRouterView>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v5/MhRouterView.vue'
	import Img2 from '@/components/Img2.vue'

	export default {
		name: 'DevView',
		components: {
			MhRouterView,
			Img2,
		},
		mixins: [ restHandler ],
		directives: {},
		props: {
		},
		data() {
			return {
				isContentReady: false,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		mounted(){
			this.isContentReady = true
		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DevView {
		padding: 1em;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
