<template>
	<img class="Img2"
		:class="elmClasses"
		:src="bestSrc"
		:data-width="width"
		:data-height="height"
		@load="onLoaded"
	/>
</template>

<script>
	export default {
		name: 'Img2',
		components: {},
		mixins: [],
		props: {
			myProp: {
				type: [String, Boolean],
				default: false,
				required: false,
			},
		},
		data(){
			return {
				isLoaded: false,
				resizeObserver: null,
				width: null,
				height: null,
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ){
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			bestSrc(){
				let src = ''

				//if( !this.isLoaded ){
					const canvas = document.createElement('canvas')
					const context = canvas.getContext('2d')
					canvas.width = 1080
					canvas.height = 720
					context.clearRect( 0, 0, canvas.width, canvas.height )
					context.fillStyle = "rgba(0, 0, 0, 0.15)"
					//context.globalAlpha = 0
					context.fillRect( 0, 0, canvas.width, canvas.height )


					const base64Canvas = canvas.toDataURL("image/png") //.split(';base64,')[1];
					//console.log('base64Canvas:', base64Canvas)
					src = base64Canvas
				/*
				}
				else{
					src = 'https://source.unsplash.com/Qh9Swf_8DyA'
				}
				*/

				return src
			},
			elmClasses(){
				let classes = []

				if( this.isLoaded ) classes.push( this.$options.name + '--isLoaded' )
				else classes.push( this.$options.name + '--isNotLoaded' )

				return classes
			},
		},
		methods: {
			onLoaded( e ){
				//this.isLoaded = true
			},
			onContainerResize( e ){
				const rect = e[0].contentRect
				console.log('onContainerResize', rect)
				requestAnimationFrame( async () => {
					//console.log( rect.width, rect.height )
					this.width = rect.width
					this.height = rect.height
				})
			},
		},
		created(){},
		mounted(){
			// attach ResizeObserver to the container
			this.resizeObserver = new ResizeObserver( this.onContainerResize )
			this.resizeObserver.observe( this.$el )
			console.log('mounted()', this.$el )

			//if( this.$el.complete ) this.isLoaded = true

			/*
			const canvas = document.createElement('canvas')
			canvas.width = 1080
			canvas.height = 720
			const base64Canvas = canvas.toDataURL("image/jpeg").split(';base64,')[1];
			console.log('base64Canvas:', base64Canvas)
			*/

			//this._.throttle( this.someFunction, 250 )
		},
		beforeDestroy(){
			// clean up the observer
			this.resizeObserver.disconnect()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	.Img2 { // debug
		[showBorders2] & {}

		//background-color: fade( blue, 25 );
		//outline: 1px solid fade( blue, 25 );
		border: 3px solid fade( blue, 25 );
	}
	.Img2 { // vars
	}
	.Img2 { // layout
		max-width: 100%;
	}
	.Img2 { // styling
		transition: opacity 0.5s ease 0.15s;
		object-fit: cover;
		//opacity: 0;

		&--isNotLoaded { background-color: fade( black, 15 ); }
		&--isLoaded { opacity: 1; }
	}
</style>
